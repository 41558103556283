'use strict';

import { localize } from "./localization.mjs"

let ev4eform = function () {

    var _init = function _init() {
        _ev3eform_stars();

        // if the overflow is detected, add the label title for the cursor hover.
        $('.form-group .align-self-center > label').bind('mouseenter', function(){
            let label = $(this);

            if (this.offsetWidth < this.scrollWidth && !label.attr('title')) {
                label.attr('title', label.text());
            }
        });
    };

    var _ev3eform_stars = function _ev3eform_stars() {
        $('.star').on('click', function () {

            let rating;
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).prevAll('.star').removeClass('active');
                rating = $(this).next('.star').data('value');
            } else {
                $(this).addClass('active');
                $(this).nextAll('.star').addClass('active');
                rating = $(this).data('value');
            }

            let id = $(this).data('hiddenid');
            $('[id="' + id + '"]').val(rating);
        })
    };

    var _onChange = function _onChange(data) {

        let selector = 'textarea[data-onchange-skript],select[data-onchange-skript],input[data-onchange-skript]';

        if (typeof data == 'string' && data.length !== 0) {
            selector = data;
        }

        $('body').on('change', selector, function () {
            var selected_element = $("#" + this.id);
            var id = AppEvewa4.getUrlParameter('id');
            var url = selected_element.data("onchangeSkript");
            var table_name = selected_element.data("onchangeTableName");
            var field_name = selected_element.data("onchangeFieldName");
            var mtable = selected_element.data("onchangeMtable");
            var formdata = selected_element.closest("form").serializeArray();

            data = {
                formdata: selected_element.closest("form").serialize(),
                field_value: selected_element.val(),
                field_id: this.id,
                field_name: field_name,
                mtable: mtable,
                id: id,
                current_table: table_name,
            };

            if (selected_element.attr('type') === 'checkbox') {
                if (selected_element.is(":checked")) {
                    // it is checked
                    data.field_value = 1;
                } else {
                    data.field_value = 0;
                }
            }

            for (var i = 0; i < formdata.length; ++i) {
                var curr = formdata[i];

                if (curr.name.endsWith("[]")) {
                    if (data[curr.name] === undefined) {
                        data[curr.name] = [];
                    }
                    data[curr.name].push(curr.value);
                } else {
                    data[curr.name] = curr.value;
                }
            }

            $.ajax({
                url: EVEWA_URL + '/evewa4/evewa4ajax.php?file=' + url,
                dataType: "json",
                async: false,
                data: data,
                type: "POST"
            })
                .done(function (data) {
                    if (data !== null) {
                        if (data.code !== undefined && data.code !== "" && data.useOnchangeResponseHandler === undefined) {
                            console.warn('Warning: Usage of eval() in onchange event detected. This way of handling on change events' +
                                ' is deprecated, onchange_response_handler should be used instead.');

                            // TODO: this should be removed altogether in future, as any action should be reworked to JSON instructions set
                            eval(data.code);
                        } else if (data.useOnchangeResponseHandler === true && data.code !== undefined && data.code !== "" && data.code !== null) {
                            let json = JSON.parse(data.code);
                            if (json.length > 0) {
                                $.each(json, function (i, val) {
                                    AppEvewa4.assignJsFile(val, true);
                                });
                            }

                            // output sweetalerts, if needed
                            if (data.status === "error") {
                                // "error" status always returns the message
                                if (data.msg !== undefined && data.msg.trim() !== "") {
                                    globalThis.swal.fire({
                                        title: "Fehler",
                                        html: data.msg,
                                        icon: "error",
                                    });
                                } else {
                                    globalThis.swal.fire({
                                        title: "Fehler",
                                        html: "Es ist ein unbekannter Fehler aufgetreten",
                                        icon: "error",
                                    });
                                }
                            } else {
                                // otherwise, return messagebox only when some text is provided
                                if (data.msg.length > 0) {
                                    let title = ''; // set the title if defined
                                    if (data.hasOwnProperty('title')) {
                                        title = data.title;
                                    }

                                    globalThis.swal.fire({
                                        title: title,
                                        icon: data.status, // 'success' / 'error' / 'warning' / 'info'
                                        html: data.msg,
                                    });
                                }
                            }
                        }
                    }
                })
                .fail(function (jqxhr, textstatus) {
                    globalThis.swal.fire({
                        title: "Anfrage fehlgeschlagen",
                        html: textstatus,
                        icon: "error",
                    });
                });
        });
    };

    /**
     * Prevents writing, clicking and focusing into kombisuche field.
     *
     * @private
     */
    var _kombisuche = function _kombisuche(data) {

        let kombisucheInput = $(data);
        // prevent clicking inside kombisuche
        kombisucheInput.on('keydown blur click focus mousedown', function (e) {
            e.preventDefault();
            event.stopImmediatePropagation();
            return false;
        });

        // if field is disabled, add no-drop cursor
        if (kombisucheInput.prop('disabled')) {
            kombisucheInput.parent().find('a.btn').css('cursor', 'no-drop')
        } else {
            kombisucheInput.parent().find('a.btn').css('cursor', 'pointer')
        }
    };

    /**
     * Initializes the numeric input mask on keypress and on paste events
     *
     * @param val value
     * @param id input
     * @param e event
     * @param decimalChar character used as decimal divider (usually "," or ".")
     * @param length maximum length of the field (including decimals)
     * @param decimal number of decimal numbers after decimal point
     * @param lang language, if it's DE, decimal point will be "," otherwise it will be "."
     */
    var _numberInputMask = function _numberInputMask(id, length, decimal, lang) {

        var decimalChar = ',';
        if (lang !== 'DE') {
            decimalChar = '.';
        }

        // display "," instead of "." as decimal separator, if the language is German
        if (lang === 'DE') {
            if ($(id).val()) {
                var value = $(id).val();
                value = value.replace('.', ',');
                $(id).val(value);
            }
        }

        // trigger keypress on triger
        $(id).on('paste', function (e) {
            // trigger keypress of number nine so keypress event is called when we paste in value
            setTimeout(function () {
                var val = $(id).val();
                validateNumericInput(val, id, e, decimalChar, length, decimal);
                // do something with text
            }, 300);
        });

        $(id).on('change', function (e) {
            var val = $(this).val();
            validateNumericInput(val, id, e, decimalChar, length, decimal);
        });
    };

    /**
     * Validates the numeric input from USER
     *
     * @param val value
     * @param id input
     * @param e event for exmaple keypress
     * @param decimalChar character used as decimal divider (usually , or .)
     * @param length maximum length of the field (including decimals)
     * @param decimal number of decimal numbers after decimal point
     */
    function validateNumericInput(val, id, e, decimalChar, length, decimal) {
        if (val.length > 0) {
            AppEvewa4.removeError(id);

            // only if we have decimal greater than 0
            if (decimal > 0) {
                // if there is no decimal (with decimal char) value, user can enter only length - 2 (because of , is an extra char) to prevent 99999999 from saving
                if (val.indexOf(decimalChar) === -1 && val.length >= (length - decimal) - 1) {
                    e.preventDefault();
                    AppEvewa4.appendError(id, 'Der eingefügte Wert ist zu lang');
                }
            }

            if (val.indexOf(decimalChar) === val.length - 1) { // user cannot enter decimalChar(,) on last places
                // if he does remove last decimalChar
                $(id).val(val.slice(0, -1));
            }

            let isnumeric = isNumeric(val, decimalChar);

            if (isnumeric === true) {
                // number
                if (val.indexOf(decimalChar) != -1) {
                    if (val.split(decimalChar)[1]) {
                        if (val.split(decimalChar)[1].length > decimal) {
                            AppEvewa4.appendError(id, 'Die maximale Anzahl von Dezimalstellen beträgt ' + decimal);
                            e.preventDefault();
                        }
                    }
                }

                if (occurrences(val, decimalChar) > 1) {
                    AppEvewa4.appendError(id, 'Sie können nur eine Dezimalstelle eingeben.');
                }

                if (val % 1 === 0 && decimal > 0 && occurrences(val, decimalChar) == 0) { // add decimals to number, if there is no decimal part already.
                    let i;
                    let zerosToAdd = '';
                    for (i = 1; i <= decimal; i++) {
                        zerosToAdd += '0';
                    }
                    $(id).val(val + decimalChar + zerosToAdd);
                }
            } else {
                // not a number
                e.preventDefault();

                // warning message, if user try to type in char
                if (val.includes('.')) {
                    AppEvewa4.appendError(id, 'Bitte benutzen Sie als Dezimaltrennzeichen ein Komma!');
                } else {
                    AppEvewa4.appendError(id, 'Bitte geben Sie eine Nummer ein!');
                }
            }
        } else {
            // no value = no error
            AppEvewa4.removeError(id);
        }
    }

    /**
     * Checks if string is numeric, if string has decimalChar(usually , or .) its also counted as decimal
     *
     * @param str
     * @param decimalChar
     * @returns {boolean}
     */
    function isNumeric(str, decimalChar) {
        if (decimalChar === ',') {
            str = str.replaceAll('.', '');
        } else {
            str = str.replaceAll(',', '');
        }

        var stringToGoIntoTheRegex = '^-?[0-9' + decimalChar + ']+$';
        var regex = new RegExp(stringToGoIntoTheRegex, "g");
        return regex.test(str);
    }

    /**
     * Finds occurances of chars in string
     *
     * @param string
     * @param subString
     * @param allowOverlapping
     * @returns {number|*}
     */
    function occurrences(string, subString, allowOverlapping) {

        string += "";
        subString += "";
        if (subString.length <= 0) return (string.length + 1);

        var n = 0,
            pos = 0,
            step = allowOverlapping ? 1 : subString.length;

        while (true) {
            pos = string.indexOf(subString, pos);
            if (pos >= 0) {
                ++n;
                pos += step;
            } else break;
        }
        return n;
    }

    var _ev4eform_iban_autocomplete = function _ev4eform_iban_autocomplete() {
        $(document).ready(function () {
            var bank_inputs = $('input[data-bank-autocomplete="1"]');
            var bind_params = {};
            $.each(bank_inputs, function (i, bi) {
                var name_no_prefix = bi.name.substring(3);
                var name_between_prentecies = name_no_prefix.substring(name_no_prefix.lastIndexOf("[") + 1, name_no_prefix.lastIndexOf("]"));
                var name_between_prentecies_lowercase = name_between_prentecies.toLowerCase();
                if (name_between_prentecies_lowercase === 'bank_verbi') {
                    name_between_prentecies_lowercase = 'bank';
                }
                if (name_between_prentecies_lowercase === 'kto_nr') {
                    name_between_prentecies_lowercase = 'konto';
                }
                bind_params[name_between_prentecies_lowercase] = name_no_prefix;
            });
            var lookupIBAN2 = new Lookup();
            lookupIBAN2.bind(bind_params);
        });
    };

    /**
     * Handles js_on_init hidden input
     *
     * @private
     */
    var _jsOnInit = function _jsOnInit() {
        let jsOnInit = $('input[name="js_on_init"]').val();

        if (jsOnInit) {
            jsOnInit = atob(jsOnInit);
            if (jsOnInit) {
                AppEvewa4.assignJsFile(jsOnInit, false);
            }
        }
    };

    /**
     * Initialization of textArea character counter for field types:
     *    - textarea
     *    - wysiwyg-textarea
     *    - ? html
     * expected setting of input field.attribute : data-max-length
     *   - if attribute data-max-length is not set ... then unlimited
     *   - attribute data-max-length is initialized during method maske.__construct()
     *      according: EV_MASKENFELDER.OPTIONS has included definition {"maxlength":"<number.integer more than 0 >"}
     * @private
     */
    let _textareaCounterInit = function _textareaCounterInit() {
        const textareaLIMIT = 'Text limit: ';
        // load all textareas located on form
        let textareaFields = $('textarea[data-max-length]');

        // property and trigger set for each field
        textareaFields.each(function () {
            // check MAX_LENGTH definition on textarea field
            let max_length_param = $(this).data('max-length');
            let max_length = (max_length_param !== undefined && max_length_param != null && max_length_param !== '') ? parseInt(max_length_param) : 0;

            // check textarea counter definition
            if ($.isNumeric(max_length) && max_length > 0) {
                // add class for limited textarea field
                $(this).addClass('limited-textarea-length');

                // set textcounter for different textarea types
                if ($(this).hasClass('wysiwyg-textarea')) {
                    // ******* textarea - powered by summernote.WYSIWYG ***********
                    // check actual lenght of summernote.WYSIWYG
                    let current_length = $(this).text().length;

                    // add label textareaCounter to summernote.WYSIWYG.output
                    // TODO: $(this).children('.note-status-output').html('<div class="textareaCounter">'
                    $(this).siblings('.note-editor').after('<div class="textareaCounter">'
                        + textareaLIMIT + current_length + ' / ' + max_length + '</div>');

                    // trigger for character limiter is set on summernote definition
                    // summernote initialization: twig_field_wysiwyg_limited.js

                } else {
                    // ******** simple textarea **************
                    // check actual lenght of textarea
                    let current_length = $(this).val().length;

                    // add label textareaCounter
                    $(this).after('<label class="textareaCounter">'
                        + textareaLIMIT + current_length + ' / ' + max_length + '</label>');

                    // add trigger for character counter
                    $(this).on('input', function () {
                        let textareaCounterInfo = $(this).next('.textareaCounter');

                        // cutout characters over the limit defined by max_lenght
                        if ($(this).val().length > max_length) {
                            // cut oversized part
                            $(this).val($(this).val().substring(0, max_length));
                            // set info box as error information
                            textareaCounterInfo.addClass('error-div');
                        } else {
                            textareaCounterInfo.removeClass('error-div');
                        }

                        // update textarea counter
                        textareaCounterInfo.text(textareaLIMIT + $(this).val().length + ' / ' + max_length);
                    });
                }
            }
        });
    };

    /**
     * Initialization of textArea field types: wysiwyg.HTML : summernote
     * Methods:
     *    init(_id, _limit)
     *       string _id :   DOM object identifier for textarea field
     *       int    _limit: max number of characters in fields (undefined or not number means unlimited textarea field)
     */
    let _wysiwyg_textarea = function () {

        const textareaLIMIT = 'Text limit: ';

        // standard settings for summernote object initialization
        const def_tabsize = 2;
        const def_lang = 'de-DE';
        const def_toolbar = [
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ol', 'ul', 'paragraph', 'height']],
            ['table', ['table']],
            ['insert', ['link']],
            ['view', ['undo', 'redo', 'codeview', 'help']]
        ];
        const def_height = 200;

        // initialize unlimited wysiwyg.textarea
        function _wysiwyg_Init(_wysiwyg_id) {
            $('#' + _wysiwyg_id).summernote({
                tabsize: def_tabsize,
                lang: def_lang,
                dialogsInBody: false,
                dialogsFade: true,
                focus: false,
                toolbar: def_toolbar,
                height: def_height,
            });
        }

        // initialize limited wysiwyg.textarea
        function _wysiwygLimited_Init(_wysiwyg_id, _max_length) {
            $('#' + _wysiwyg_id).summernote({
                tabsize: def_tabsize,
                lang: def_lang,
                dialogsInBody: false,
                dialogsFade: true,
                focus: false,
                toolbar: def_toolbar,
                height: def_height,
                maxTextLength: _max_length + 1,
                callbacks: {
                    onInit: function () {
                        if ($(this).summernote('isEmpty')) {
                            $(this).summernote('code','');
                        }

                        let innerText = $("<div />").html($(this).summernote('code')).text();

                        _wysiwygCharCouterInfo($(this), innerText, _max_length);
                    },
                    onPaste: function (e) {
                        let innerText = e.currentTarget.innerText;
                        let htmlText = $(this).summernote('code');
                        let bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                        if ((htmlText.length + bufferText.length) > _max_length) {
                            e.preventDefault();
                        }

                        _wysiwygCharCouterInfo($(this), innerText, _max_length);
                    },
                    onFocus: function (contents) {
                        if($(this).summernote('isEmpty')){
                            $(this).summernote('code','');
                        }
                    },
                    onChange: function (contents) {
                        if ($(this).summernote('isEmpty') && contents !== '') {
                            // in case the empty paragraph is auto-inserted: '<br>' or sequence '<p><br></p>'
                            $(this).summernote('code','');
                        }

                        let innerText = $("<div />").html($(this).summernote('code')).text();

                        _wysiwygCharCouterInfo($(this), innerText, _max_length);
                    },
                },
            });

            //
        }

        // set Counter information for limited wysiwyg.textarea Limit
        function _wysiwygCharCouterInfo(_wysiwygObject, _text, _max_length) {
            // set the counter info object definition
            let textareaCounterInfo = _wysiwygObject.siblings('.textareaCounter').first();

            // cut oversized part ... in case of paste text (CTRL-V)
            //    .. other inserts are automaticaly cut because of set summernote.maxTextLength
            if (_text.length > _max_length) {
                _text = _text.substring(0, _max_length);
            }

            // extract Html text from summernote object
            let finalHtmlText = _wysiwygObject.summernote('code');

            // set/unset info box error information: max limit reached
            if (finalHtmlText.length >= _max_length) {
                textareaCounterInfo.addClass('error-div');
                // _event.preventDefault();
            } else {
                textareaCounterInfo.removeClass('error-div');
            }

            // update textarea counter
            let fieldtextLength = textareaLIMIT + _text.length + ' / ' + _max_length;
            if (_text.length < finalHtmlText.length) {
                fieldtextLength += ' (HTML characters: ' + finalHtmlText.length + ' )';
            }
            textareaCounterInfo.text(fieldtextLength);
        }

        return {
            init: function (_id, _limit) {
                if (_limit === undefined || !(isNumeric(_limit, ',') && _limit > 0)) {
                    _wysiwyg_Init(_id);
                } else {
                    _wysiwygLimited_Init(_id, parseInt(_limit));
                }
            }
        };
    }();


    /**
     * Initialization of simple Tooltips for required fields, see ticket EVEWA4-2052
     * @private
     */
    let _requiredFieldsTooltip = function _requiredFieldsTooltip() {
        /* tooltip for labels containing an asterisk */
        localize('MASKE').then(function (__) {
            let required_label = __('Pflichtfeld');
            let requiredFields = $("label.form-control-label:contains('* ')");
            requiredFields.tooltip({
                placement: "top",
                delay: 150,
                html: true,
                trigger: "hover",
                container: "body",
                template: "<div class=\'m-tooltip tooltip top bs-tooltip-top\' role=\'tooltip\'><div class=\'tooltip-arrow arrow\'></div><div class=\'tooltip-inner\'></div></div>",
                title: required_label,
            }).addClass('tooltip-hint');

            // TODO: apply only when checkbox use-case is fixed.
            // requiredFields.each(function () {
            //     let cleanLabel = $(this).text().substring(2);
            //     $(this).text(cleanLabel);
            //     $(this).prepend("<span class='required-attr'>*</span>");
            // });
        });
    };


    return {
        init: function init() {
            _init();
        },
        onChange: function onChange(data) {
            _onChange(data);
        },
        kombisuche: function kombisuche(data) {
            _kombisuche(data);
        },
        textareaCounterInit: function textareaCounterInit() {
            _textareaCounterInit();
        },
        wysiwyg_textareaInit: function wysiwyg_textareaInit(id,limit) {
            _wysiwyg_textarea.init(id, limit);
        },
        numberInputMask: function numberInputMask(id, length, decimal, lang) {
            _numberInputMask(id, length, decimal, lang);
        },
        getLanguage: function getLanguage() {
            _getLanguage();
        },
        jsOnInit: function jsOnInit() {
            _jsOnInit();
        },
        requiredFieldsTooltip: function requiredFieldsTooltip() {
            _requiredFieldsTooltip();
        }
    };
}();

globalThis.ev4eform = ev4eform;
export default ev4eform;

ready().then(function () {
    ev4eform.textareaCounterInit();
    ev4eform.requiredFieldsTooltip();
    ev4eform.onChange();
}).then(function() {
    setModuleReady('eform');
});

